.inherit-w {
    width: 100%;
}
.inherit-h {
    height: 100%;
}
.inherit-size {
    width: 100%;
    height: 100%;
}

.zero {
    margin: 0;
    border: none;
    padding: 0;
}

.zero-l {
    margin-left: 0;
    border: none;
    padding-left: 0;
}

.zero-r {
    margin-right: 0;
    border: none;
    padding-right: 0;
}
