.xxl-es {
	height: 120px;
	width: 100%;
}

.xl-es {
	height: 100px;
	width: 100%;
}

.md-es-desc {
	height: 38px;
	width: 100%;
}

.md-es {
	height: 40px;
	width: 100%;
}

.sm-es-desc {
	height: 17px;
	width: 100%;
}

.sm-es {
	height: 20px !important;
	width: 100%;
}

.xs-es {
	height: 10px;
	width: 100%;
}

.x-xs-es {
	height: 2px;
	width: 100%;
}

.tiny-es {
	height: 1px;
	width: 100%;
}

.vspace-xs {
	width: 10px;
	height: 100%;
}

.m-0 {
	margin: 0;
}

.ml-1 {
	margin-left: 4px;
}
.ml-2 {
	margin-left: 8.5px;
}
.ml-3 {
	margin-left: 12px;
}
.ml-4 {
	margin-left: 16.5px;
}
.ml-5 {
	margin-left: 20px;
}
.ml-6 {
	margin-left: 24.5px;
}
.ml-7 {
	margin-left: 28px;
}
.ml-8 {
	margin-left: 32.5px;
}

.mr-1 {
	margin-right: 4px;
}
.mr-2 {
	margin-right: 8.5px;
}
.mr-3 {
	margin-right: 12px;
}
.mr-4 {
	margin-right: 16.5px;
}
.mr-5 {
	margin-right: 20px;
}
.mr-6 {
	margin-right: 24.5px;
}
.mr-7 {
	margin-right: 28px;
}
.mr-8 {
	margin-right: 32.5px;
}

.my-1 {
	margin-top: 1px;
	margin-bottom: 1px;
}

.my-2 {
	margin-top: 2px;
	margin-bottom: 2px;
}
.my-4 {
	margin-top: 4px;
	margin-bottom: 4px;
}

.p-1 {
	padding: 1px;
}

.p-2 {
	padding: 2px;
}

.p-4 {
	padding: 4px;
}

.p-8 {
	padding: 8px;
}

.px-1 {
	padding-left: 1px;
	padding-right: 1px;
}

.px-2 {
	padding-left: 2px;
	padding-right: 2px;
}

.px-16 {
	padding-left: 16px;
	padding-right: 16px;
}

.px-36 {
	padding-left: 36px;
	padding-right: 36px;
}

.pt-1 {
	padding-top: 1px;
}

.pt-2 {
	padding-top: 2px;
}

.pt-3 {
	padding-top: 3px;
}

.pt-4 {
	padding-top: 4px;
}

.no-pl {
	padding-left: 0px;
}

.no-pr {
	padding-right: 0px;
}
