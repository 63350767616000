.absolute_parent {
    position: relative;
}

.sub_link {
    color: #173db8;
    text-decoration: none;
    position: relative;
    margin: 5px 0 !important;
    display: block;
    font-size: 14px;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: #173db8;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }

    &:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

.dropdown {
    position: absolute;
    left: 0px;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    background-color: #ededed;
    cursor: pointer;
    z-index: 50;
}

.dropdown_list {
    padding: 0;
}
