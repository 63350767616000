.slider_text {
    margin: 0;
    color: #222222;
    font-weight: bold;
    font-size: 16px;
}

.btn_text {
    font-size: 12px !important;
    height: 40px;
}
.btn_icon {
    font-size: 20px !important;
    height: 90px;
    //border-radius: 50px !important;
}
