$primary-text-color: #222222;
$option-background: #ededed;

.link {
    box-sizing: border-box;
    color: $primary-text-color;
    font-family: 'Lato';
    font-weight: bold;
    font-size: 16px;
    margin-top: 5px;
    border-radius: 5px;
    padding: 5px;

    &:hover {
        cursor: pointer;
        background-color: $option-background !important;
    }

    &__sub {
        margin-left: 16.5px;
    }
}
