.modal {
	position: fixed;
	z-index: 500;
	background-color: white;
	max-height: 60vh;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0px 2px 5px #666;
	top: 20vh;
	box-sizing: border-box;
	overflow: auto;

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: #ededed;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(23, 61, 184, 0.75);
	}
}
