$primary_color: #a5da00;
$title_color: #173db8;
$warn: #f0a71f;
$text_color: white;

p {
	text-align: justify;
	word-break: break-word;
	margin: 0;
	font-family: 'Lato', sans-serif;
}

hr {
	border: none;
	height: 1px;
	width: 100%;
	background-color: $primary_color;
}

hr.light {
	border: none;
	height: 1px;
	background-color: #1c3545;
}

hr.gray {
	border: none;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.2);
}

legend {
	max-width: 1000px !important;
	background-color: white !important;
	font-size: 0.75em !important;
	text-align: left !important;
	height: 11px !important;
	width: auto !important;
	padding: 0 !important;
	display: block !important;
	visibility: hidden !important;
	min-width: 100%;
}

text {
	color: #222222;
	font-family: Roboto, sans-serif;
}

span.mini-link {
	color: #323233;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.primary {
	color: $primary_color;
}
.secondary {
	color: $title_color;
}

.btn-add {
	font-size: 24px;
	color: $text_color !important;
	background-color: $primary_color !important;
	width: 50px;
	height: 50px;
	padding: 0;
	border: none;
	border-radius: 50px;
	margin-bottom: 20px;
	-webkit-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	//pointer-events: none;
	cursor: pointer;
	outline-width: 0;
	position: fixed !important;
	bottom: 5vh;
	right: 5vw;
}

.btn-rmv {
	font-size: 24px;
	color: $text_color !important;
	background-color: $warn !important;
	padding: 0;
	border: none;
	border-radius: 50px;
	margin-bottom: 20px;
	-webkit-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	cursor: pointer;
	outline-width: 0;
	float: right;
}

.fl-right {
	float: right;
}

.flex-start {
	align-self: flex-start;
}

.flex-center {
	align-self: center;
}

.items-center {
	align-items: center !important;
}

.flex-end {
	justify-content: flex-end;
}

.tab-title {
	font-family: 'Lato', sans-serif;
	color: $title_color;
	padding-left: 30px;
	font-size: 24px;
}

.spinner-text,
.tab-subtitle {
	margin: 0;
	color: #222222;
	font-weight: bold;
	font-size: 16px;
}

.err-text {
	margin: 0;
	color: #f44336;
	font-size: 16px;
}

.secondary-text {
	margin: 0;
	color: $title_color;
	font-size: 16px;
}

.alert-title {
	font-family: 'Lato', sans-serif;
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;
	color: #173db8;
}

.success-toast {
	background-color: $title_color !important;
	border-radius: 5px !important;
}

.App-logo {
	width: 150px;
}

.fixed-h {
	box-sizing: content-box;
	max-height: calc(100vh - 215px);
	height: calc(100vh - 305px);
}

.fixed-scroll {
	box-sizing: content-box;
	max-height: calc(100vh - 215px);
	overflow: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: #ededed;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(23, 61, 184, 0.75);
	}
}

.fixed-scroll-md {
	box-sizing: content-box;
	max-height: calc(100vh - 256px);
	overflow: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: #ededed;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(23, 61, 184, 0.75);
	}
}

.fixed-scroll-xs {
	box-sizing: content-box;
	max-height: calc(100vh - 356px);
	overflow: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: #ededed;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(23, 61, 184, 0.75);
	}
}

.txt-center {
	text-align: center;
}

.txt {
	margin: 0;
	color: #222222;
	font-weight: bold;
	font-size: 16px;
}

.align-center {
	align-self: center;
}

@media screen and (max-width: 1447px) {
	.App-logo {
		width: 100px !important;
	}
}
