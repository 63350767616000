.card_header {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #1c3545;
    background-color: #d4ec8e;
    width: calc(100% - 60px);
    padding: 10px 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    box-sizing: border-box;
    margin-left: 30px;
    margin-right: 30px;
}

.header {
    margin: 0;
    color: #222222;
    font-weight: bold;
    font-size: 14px;
}

.header_secondary {
    margin: 0;
    color: #1c3545;
    font-weight: bold;
    font-size: 12px;
}

.header_icon {
    font-size: 14px;
    color: #222222;
}

.card_content {
    background-color: #ededed;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
}

.content {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 90%;

    p {
        padding: 2px 15px;
    }
}

.delete_container {
    position: relative;
    width: 100%;
    height: 30px;
}

.delete_btn {
    position: absolute !important;
    right: 0;
}
