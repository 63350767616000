$primary-text-color: #222222;
$secondary-text-color: #808080;
$alternative-text-color: #f44336;
$option-background: #ededed;

li {
	color: #323233;
	list-style-type: none;
	font-family: 'Lato', sans-serif;
	font-weight: bold;
	font-size: 16px;
}

h2 {
	color: #323233;
	font-family: 'Khand', sans-serif;
	font-size: 26px;
}

.app_title {
	z-index: 300;
}

.nav_bar {
	width: 100%;
	height: 90px;
	//border: 1px solid black;
}

.navbar_toggle {
	color: #202020;
	font-size: 24px;
	border-radius: 50px;
	width: 50px;
	height: 50px;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: $option-background !important;
	}
}

.logo {
	height: 60px;
}

.link {
	text-decoration: none;
	color: #173db8;
	font-family: 'Lato', sans-serif;
	font-weight: bold;
	font-size: 16px;
	transition: 500ms;
	padding: 10px;
}

.link_hover {
	color: #173db8;
	text-decoration: none;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: -5px;
		left: 0;
		background-color: #173db8;
		visibility: hidden;
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transition: all 0.3s ease-in-out 0s;
		transition: all 0.3s ease-in-out 0s;
	}

	&:hover:before {
		visibility: visible;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

.link_active {
	background-color: #173db8;
	color: #f1f1f1;
	border-radius: 5px;
	padding: 10px;
}

.secondary_link {
	color: #323233;
	text-decoration: none;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: -5px;
		left: 0;
		background-color: #323233;
		visibility: hidden;
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transition: all 0.3s ease-in-out 0s;
		transition: all 0.3s ease-in-out 0s;
	}

	&:hover:before {
		visibility: visible;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

.secondary_link > a {
	text-decoration: none;
	color: #323233;
}

.user__heading {
	font-family: 'Lato';
	font-weight: bold;
	font-size: 16px;
	color: $primary-text-color;
	text-align: right;
}
.user__subheading {
	font-family: 'Lato';
	font-weight: normal;
	font-size: 12px;
	color: $secondary-text-color;
	text-align: right;
}

.userWrapper {
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 10px;
	border-bottom: 5px;

	&:hover {
		cursor: pointer;
		background-color: $option-background;
		border-radius: 0 0 16px 16px;
	}
}

.miniMenu {
	z-index: 250;
	position: absolute;
	top: calc(90px + 16px);
	right: 16px;
	border-radius: 5px;
	padding: 10px;
	box-shadow: 0px 1px 5px #666;

	span {
		color: $alternative-text-color;
		padding: 5px;
		border-radius: 4px;
		cursor: pointer;

		&:hover {
			background-color: $option-background;
		}
	}
}
