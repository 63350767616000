$fill: #e6edf5;
$separation: #ffffff;

.map_container {
    width: 100%;
    height: 70vh;
}

.sidebar_style {
    display: inline-block;
    margin: 12px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
}
