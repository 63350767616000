// Global styles.
@import './assets/styles/flexbox-grid.scss';
@import './assets/styles/size.scss';
@import './assets/styles/spacing.scss';
@import './assets/styles/common.scss';

// Fonts.
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap');

body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.clickable {
    cursor: pointer;
}
