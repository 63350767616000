.app_title {
    font-size: 80px;
    margin: 0;
}

.app_description {
    color: #808080;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    text-align: justify;
    word-break: normal;
}
