.link {
    color: #323233;
    text-decoration: none;
    position: relative;
    border: none;
    background-color: transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: #323233;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }

    &:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
