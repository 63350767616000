$fill: #e6edf5;
$separation: #ffffff;

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    height: 40vh;
    width: 100%;
}

.svg {
    background: $fill;
    background-image: linear-gradient($separation 0.1em, transparent 0.1em),
        linear-gradient(90deg, $separation 0.1em, transparent 0.1em);
    background-size: 1.25% 10%;
    height: 100%;
    width: 100%;
    margin: auto;
    overflow: visible;
}

.svg_container {
    background: $fill;
    background-image: linear-gradient($separation 0.1em, transparent 0.1em),
        linear-gradient(90deg, $separation 0.1em, transparent 0.1em);
    background-size: 1.25% 10%;
    height: 100%;
    width: 100%;
    margin: auto;
    overflow: visible;
}

.text {
    margin: 0;
    color: #222222;
    font-weight: bold;
    font-size: 16px;
}
