$primary-text-color: #222222;
$secondary-text-color: #808080;
$alternative-text-color: #f44336;
$option-background: #ededed;

.wrapper {
	position: fixed;
	box-sizing: border-box;
	left: 0;
	top: 90px;
	height: calc(100vh - 90px);
	min-width: 50px;
	max-width: 500px;
}

.sideBar {
	z-index: 250;
	background-color: white;
	margin-top: calc(15vh - 90px);
	margin-bottom: calc(15vh + 90px);
	border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
	border: 1px solid #ccc;
	box-shadow: 0px 2px 5px #666;
	padding: 2rem;
	max-height: 70vh;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	flex-flow: wrap;

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: #ededed;
		margin-top: 130px;
		margin-bottom: 30px;
	}

	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(23, 61, 184, 0.75);
	}
}

.user_info {
	width: calc(350px - 4rem);
	min-width: calc(350px - 4rem);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	box-sizing: border-box;

	.user_heading {
		font-family: 'Lato';
		font-weight: bold;
		font-size: 16px;
		color: $primary-text-color;
	}
	.user_subheading {
		font-family: 'Lato';
		font-weight: normal;
		font-size: 12px;
		color: $secondary-text-color;
	}
	.line {
		width: 100%;
		background-color: $primary-text-color;
	}
}

.usefull_links {
	margin-top: 20px;
	width: calc(350px - 4rem);
	min-width: calc(350px - 4rem);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	box-sizing: border-box;

	span {
		font-family: 'Lato';
		font-weight: bold;
		font-size: 16px;
		padding: 5px;
		border-radius: 5px;

		&:hover {
			cursor: pointer;
			background-color: $option-background;
		}
	}

	.special_red {
		color: $alternative-text-color;
	}
	.default {
		margin-top: 5px;
		color: $primary-text-color;
	}
}

.links {
	margin-top: 15px;
	width: calc(350px - 4rem);
	min-width: calc(350px - 4rem);
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	box-sizing: border-box;
}
