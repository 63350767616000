.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    height: 40vh;
}

.svg {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    margin: auto;
}

.svg .geo_chart_location:hover {
    stroke: #f0f0f0;
    stroke-width: 3px;
    cursor: pointer;
}
