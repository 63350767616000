.center_main {
	justify-content: center;
	display: inline;
}

.center {
	justify-content: center;
	align-items: center;
}

.container {
	min-width: 500px;
	width: 960px;
	max-width: 960px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
